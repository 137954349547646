@import './../../root.scss';

.all-forms {
  max-width: 650px;
  margin: 0 auto;

  @media #{$Mobile} {
    width: auto;
    margin: 0 20px;
  }
}

.link:hover {
  text-decoration: none !important;
}

.card {
  border: 1px solid gainsboro;
  border-radius: 15px;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  color: #000;
  background-color: #fff;
  transition: .3s ease all;
  margin: 30px 0;

  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }

  .title {
    padding: 20px 0 5px;
  }
}

.image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}