.title {
  padding: 0;
  margin: 0;
}

.tabs {
  margin-top: 25px;
}

.statisticsCounts {
  width: fit-content;
  background: #fff;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 7px;

  div {
padding: 0 10px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #d9d9d9;

    &:last-child {
      border: none;
    }

    b {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}