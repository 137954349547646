@import 'mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  padding: 0;
  margin: 0;
}

.navbar-expand {
}

.contents {
  display: contents;
  height: 100%;
  width: 100%;
}

label {
  display: block;
  margin-top: 10px;
}

#root {
  background-color: #f7f7f7;
  min-height: 100%;

  display: flex;
  flex-direction: column;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card-container__title {
  margin: 0 auto 30px;
}

.card-container__img {
  width: calc(100% - 100px);
  transform: scale(-1, 1);
  position: absolute;
  margin: 0 auto;
  top: 0;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.logo {
  width: 170px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.city {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 50px;
}

.nav-link {
  padding: 0;
}

ul.navbar-nav {
  width: 100%;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
